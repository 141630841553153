/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Image, Text, SeparateLine, Subtitle, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1vu217l --style3 --full" anim={""} name={"uvod"} animS={"3"} border={""} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/acaeb09e071f485e8c1c83d7288cd794_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/acaeb09e071f485e8c1c83d7288cd794_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/acaeb09e071f485e8c1c83d7288cd794_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/acaeb09e071f485e8c1c83d7288cd794_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/acaeb09e071f485e8c1c83d7288cd794_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/acaeb09e071f485e8c1c83d7288cd794_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/acaeb09e071f485e8c1c83d7288cd794_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/acaeb09e071f485e8c1c83d7288cd794_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":1390}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"5"} style={{"maxWidth":1425}}>
              
              <Title className="title-box title-box--left fs--43 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"<br><br>Hlídání a výcvik psů"}>
              </Title>

              <Button className="btn-box btn-box--shape4 btn-box--left fs--16 mt--20" use={"page"} href={"/sluzby"} style={{"maxWidth":1000}} content={"MÉ SLUŽBY"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--50" name={"sluzby"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26402/5ef6b7aeea684f0abab48d68d73ddda7_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":209}} srcSet={"https://cdn.swbpg.com/t/26402/5ef6b7aeea684f0abab48d68d73ddda7_s=350x_.jpg 350w"} position={null}>
              </Image>

              <Title className="title-box" content={"Výcvik psů"}>
              </Title>

              <Text className="text-box" content={"Individuální výcviky založené na pozitivním přístupu  bez fyzických trestů přizpůsobené vždy potřebám pejska i majitele."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26402/6bb92f94664f49669eb09f060eb902bc_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":224}} srcSet={"https://cdn.swbpg.com/t/26402/6bb92f94664f49669eb09f060eb902bc_s=350x_.jpg 350w"} position={null}>
              </Image>

              <Title className="title-box" content={"Hlídání mazlíčků"}>
              </Title>

              <Text className="text-box" content={"Dlouhodobé i krátkodobé hlídání psů, koček a ostatních domácích mazlíčků u mě nebo u majitele doma."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26402/a233ae06e5754951ae97f639a174a60b_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":167}} srcSet={"https://cdn.swbpg.com/t/26402/a233ae06e5754951ae97f639a174a60b_s=350x_.jpg 350w"} position={null}>
              </Image>

              <Title className="title-box" content={"Venčení psů"}>
              </Title>

              <Text className="text-box" content={"Libovolně dlouhé a aktivní procházky včetně her zajistí pejskovi kvalitně strávený čas v nepřitomnosti majitele."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"citat"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":1670}}>
              
              <Image className="--center" src={"https://cdn.swbpg.com/o/26402/54cf82cace06428b849377c879d318f6.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":100}} srcSet={""} lightbox={false}>
              </Image>

              <Title className="title-box lh--1" style={{"maxWidth":1000,"marginTop":0}} content={"let zkušeností"}>
              </Title>

              <Image src={"https://cdn.swbpg.com/o/26402/b04409c1790340f785cd3fa76bfc47e7.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":100}} srcSet={""} lightbox={false}>
              </Image>

              <Title className="title-box lh--12" style={{"marginTop":0}} content={"let studia"}>
              </Title>

              <Image src={"https://cdn.swbpg.com/t/26402/0eff407638fe46e0a486f6670b7ddfc7_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":120}} srcSet={"https://cdn.swbpg.com/t/26402/0eff407638fe46e0a486f6670b7ddfc7_s=350x_.jpg 350w"} lightbox={false}>
              </Image>

              <Title className="title-box" style={{"marginTop":0}} content={"spokojených pejsků"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ydd143y2sm"} style={{"backgroundColor":"rgba(44,45,54,1)"}} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/731de98eed384084be19cf88b9d42ade_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/731de98eed384084be19cf88b9d42ade_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/731de98eed384084be19cf88b9d42ade_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/731de98eed384084be19cf88b9d42ade_s=860x_.JPG 860w, https://cdn.swbpg.com/t/26402/731de98eed384084be19cf88b9d42ade_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box" content={"\"Klára je milá a velmi pečlivá. Při hlídání naší temperamentní fenky Sofie se z nich rychle staly parťačky a bylo vidět, že si společný čas užily :). Oceňuji, že nám Klára pravidelně posílala fotky a informace o tom, co zrovna dělají. Budeme se vracet!\" - Zdeněk K. (Německý boxer)\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/da7e2d9714b846caacb24946ae30def8_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/da7e2d9714b846caacb24946ae30def8_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/da7e2d9714b846caacb24946ae30def8_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/da7e2d9714b846caacb24946ae30def8_s=860x_.JPG 860w, https://cdn.swbpg.com/t/26402/da7e2d9714b846caacb24946ae30def8_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box" content={"\"Barbara \nKlara is just awesome. You can really see that she loves animals. Our dog just loves her. She is really great at sending us update photos. I would totally recommend Klara.\" -&nbsp;Barbara W. (Labradorský retrívr)"}>
              </Text>

              <Button className="btn-box btn-box--shape3" use={"page"} href={"/reference"} content={"Další reference"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/26402/559a95b7e9164fcba6dd82c5ff40a162_s=660x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/26402/559a95b7e9164fcba6dd82c5ff40a162_s=350x_.JPG 350w, https://cdn.swbpg.com/t/26402/559a95b7e9164fcba6dd82c5ff40a162_s=660x_.JPG 660w, https://cdn.swbpg.com/t/26402/559a95b7e9164fcba6dd82c5ff40a162_s=860x_.JPG 860w"}>
              </Image>

              <Text className="text-box" content={"\"Klára je skvělá hlídačka našeho čtyřnohého ohařího kamaráda. Klárka si pejska pravidelně vyzvedává přes oběd a posílá nám fotky jak se pejsek má.\nVelmi si u Kláry ceníme nejen jejího perfektního přátelského přístupu, ale že spolupráce není jen o venčení, ale i o jejím přístupu k pejskovi a jeho výchově.\n\nPřála bych každému mít hlídaní pejska jako nám zajišťuje Klára :-)\" -&nbsp;Nikola R. (Maďarský ohař)"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"informace-2"} lightbox={false}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box" content={"O mně"}>
              </Title>

              <Text className="text-box" content={"Velkou milovnicí zvířat jsem odjakživa. Své dětství jsem strávila u koní a ve veterinární ordinaci, kam jsem chodila pomáhat. Už v té době jsem měla jasno v tom, co chci dělat. Zřejmě vás tedy nepřekvapí, že následovalo studium veteriny. Jako vždy jsem se ale rozhodla jít vlastní cestou a zasvětila svůj život primárně psům..."}>
              </Text>

              <Button className="btn-box btn-box--shape4 mt--20" use={"page"} href={"/o-mne"} content={"Více informací"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26402/918bc4f1f14a4f9bbde662584193fa4f_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":489}} srcSet={"https://cdn.swbpg.com/t/26402/918bc4f1f14a4f9bbde662584193fa4f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/26402/918bc4f1f14a4f9bbde662584193fa4f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/26402/918bc4f1f14a4f9bbde662584193fa4f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/26402/918bc4f1f14a4f9bbde662584193fa4f_s=1400x_.jpg 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="css-1m5y688 pb--08 pt--80" name={"rwe9ctfecgk"} style={{"marginTop":0,"marginBottom":0}} layout={"l1"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/26402/efd0b60048dc4aef92d9cfaa4066aeca_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/26402/efd0b60048dc4aef92d9cfaa4066aeca_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/26402/efd0b60048dc4aef92d9cfaa4066aeca_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/26402/efd0b60048dc4aef92d9cfaa4066aeca_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/26402/efd0b60048dc4aef92d9cfaa4066aeca_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/26402/efd0b60048dc4aef92d9cfaa4066aeca_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/26402/efd0b60048dc4aef92d9cfaa4066aeca_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/26402/efd0b60048dc4aef92d9cfaa4066aeca_s=3000x_.jpg);
    }
  
    `}>
        </SeparateLine>


        <Column className="pb--60 pt--60" name={"mx6t2j9s5fc"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" style={{"color":"var(--color-variable-1)"}} content={"Máte zájem o mé služby?"}>
              </Subtitle>

              <Title className="title-box" content={"Naplánujte si se mnou bezplatnou konzultaci!"}>
              </Title>

              <Subtitle className="subtitle-box mt--16" content={"Kontaktujte mě prosím emailem:"}>
              </Subtitle>

              <Text className="text-box fs--16" style={{"maxWidth":740}} content={"Vyplňte prosím formulář níže a já se Vám v co nejkratším možném termínu ozvu zpátky."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape3 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--0" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Image src={"https://cdn.swbpg.com/o/26402/df60f83ccf2f4be8867ec8bcdeed2086.svg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Text className="text-box" content={"info@dogmom.cz\n\n<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}